export const ENDPOINTS = {
  HEADER: `/home/header`,
  AGENDACARD: `/projects/marketplace`,
  IMPACTPOINT: `/home/impact-points`,
  AVATAR: `/home/heroes`,
  BLOG: `/home/blogs`,
  FEATUREMEDIA: `/home/featured-on-media`,
  ROADMAP: `/home/roadmap`,
  ENQUIRYFORM: `/home/enquiry`,
  SIGNUP: `/users/hero/signup`,
  COUNTRYLISTS: `/users/country`,
  LOGIN: `/admin/login`,
  HERODETAILS: `/projects/hero`,
  PROJECTDETAILS: `/projects/hero`,
  PROJECTLISTS: `/projects/list`,
  PAYMENT: `/projects/create/payment-intent`,
  PAYMENTSUCCESS: `/projects/payment-success`,
  FEEDBACK: `/home/feedback`,
};
