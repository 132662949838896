import React, { useState } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import arrowIcon from '../assets/icons/arrow-icon.svg';
import { FeedBackForm } from '../services/Homepage';
import { toast } from 'react-toastify';
import { isAxiosError } from 'axios';

interface FeedBackProps {
  handleClose: () => void;
}
const FeedBackPop: React.FC<FeedBackProps> = ({ handleClose }) => {
  const [validated, setValidated] = useState(false);
  const [formtData, setFormData] = useState({
    name: '',
    email: '',
    feedback: '',
    is_visitor_feedback: 1,
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    // Set the form data state
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Custom validation
    if (name !== 'address') {
      const isValidInput = value.trim() !== '' && /\S/.test(value);
      e.target.setCustomValidity(
        isValidInput ? '' : 'Invalid input: Please enter a valid character.'
      );
    } else {
      // Allow whitespace in the address field
      e.target.setCustomValidity('');
    }
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity() === false) {
        setValidated(true);
      } else {
        const resp = await FeedBackForm(formtData);
        toast.dismiss();
        toast.success(`${resp.message}`, {
          position: 'top-right',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        handleClose();
      }
    } catch (error) {
      if (isAxiosError(error) && error?.response?.data?.message[0]?.message) {
        toast.dismiss();
        toast.error(`${error?.response?.data?.message[0]?.message}`, {
          position: 'top-right',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label className="text-dark">Name</Form.Label>
        <span className="text-danger">*</span>
        <Form.Control
          type="text"
          placeholder="Enter Name"
          name="name"
          maxLength={100}
          required
          onChange={handleChange}
        />
        <Form.Control.Feedback type="invalid">
          Please provide a valid name.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="text-dark">Email</Form.Label>
        <span className="text-danger">*</span>
        <Form.Control
          type="email"
          placeholder="Enter email"
          name="email"
          required
          onChange={handleChange}
        />
        <Form.Control.Feedback type="invalid">
          Please provide a valid email.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="text-dark">Feedback</Form.Label>
        <span className="text-danger">*</span>
        <Form.Control
          as="textarea"
          rows={5}
          name="feedback"
          maxLength={1000}
          required
          onChange={handleChange}
        />
        <Form.Control.Feedback type="invalid">
          Please provide your feedback.
        </Form.Control.Feedback>
      </Form.Group>
      <Button type="submit" variant="primary" size="lg" className="w-100">
        SUBMIT
        <Image src={arrowIcon} alt="Impactivator Btn" className="mx-2" />
      </Button>
    </Form>
  );
};

export default FeedBackPop;
