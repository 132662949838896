import React, { useState } from 'react';
import { Button, Image, Modal, Form } from 'react-bootstrap';
import arrowIcon from '../assets/icons/arrow-icon.svg';
import { adminLogin } from '../services/Auth';
import { toast } from 'react-toastify';
import { isAxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { login } from '../redux/authSlice';
// import { useNavigate } from 'react-router-dom';
import { Roles } from '../models/Roles';
import openEye from '../assets/icons/eye.svg';
import closeEye from '../assets/icons/eye-slash.svg';
interface LoginFormProps {
  handleClose: () => void;
}
const Loginform: React.FC<LoginFormProps> = ({ handleClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  // const navigate = useNavigate();
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;

    // Reset custom validity messages
    const passwordField = form.querySelector(
      'input[type="password"]'
    ) as HTMLInputElement | null;
    if (passwordField) {
      passwordField.setCustomValidity('');
    }

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      try {
        const response = await adminLogin(email, password);
        toast.dismiss();
        toast.success(`${response.message}`, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        dispatch(
          login({
            token: response.access_token,
            role: response.data.role,
            id: response.data.id,
          })
        );
        if (response.data.role === Roles.Impactor) {
          // navigate('/projects');
        } else {
          window.location.href = '/admin/homepage';
        }

        handleClose();
      } catch (err) {
        if (isAxiosError(err) && err?.response?.data?.message) {
          toast.dismiss();
          toast.error(`${err?.response?.data?.message}`, {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      }
    }

    setValidated(true);
  };

  const handleNavigate = () => {
    window.location.href = '/admin/forgot-password';
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3 className="text-dark m-0 fw-bold">Sign In</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label className="text-dark">Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>

          {/* <Form.Group className="mb-3 position-relative">
            <Form.Label className="text-dark">Password</Form.Label>
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              minLength={8}
            />
            <span
              className="position-absolute pe-2"
              onClick={togglePasswordVisibility}
              style={{ cursor: 'pointer', top: '15px', right: '0' }}
            >
              {showPassword ? (
                <img
                  src={closeEye}
                  alt="Hide password"
                  width="20"
                  height="20"
                />
              ) : (
                <img src={openEye} alt="Show password" width="20" height="20" />
              )}
            </span>
            <Form.Control.Feedback type="invalid">
              Please provide a valid password.
            </Form.Control.Feedback>
          </Form.Group> */}
          <Form.Group className="mb-3">
            <Form.Label className="text-dark">Password</Form.Label>
            <div className="position-relative">
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                minLength={8}
                className="pr-5"
              />
              <span
                className={`position-absolute pe-2 ${validated ? 'eye-icon-with-validation' : ''}`}
                onClick={togglePasswordVisibility}
                style={{ cursor: 'pointer', top: '15px', right: '0' }}
              >
                {showPassword ? (
                  <img
                    src={closeEye}
                    alt="Hide password"
                    width="20"
                    height="20"
                  />
                ) : (
                  <img
                    src={openEye}
                    alt="Show password"
                    width="20"
                    height="20"
                  />
                )}
              </span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid password.
              </Form.Control.Feedback>
            </div>
          </Form.Group>

          <div className="d-flex justify-content-end mb-3">
            <span
              style={{ cursor: 'pointer' }}
              className="text-dark  text-decoration-underline"
              onClick={handleNavigate}
            >
              Forgot Password?
            </span>
          </div>

          <Button type="submit" variant="primary" size="lg" className="w-100">
            LOGIN
            <Image src={arrowIcon} alt="Impactivator Btn" className="mx-2" />
          </Button>
        </Form>
      </Modal.Body>
    </>
  );
};

export default Loginform;
