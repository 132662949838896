import React, { useEffect, useState } from 'react';
import '../../../styles/css/header.css';
import {
  Navbar,
  Container,
  Nav,
  Image,
  Button,
  Modal,
  NavDropdown,
  Dropdown,
  // NavDropdown,
  // Dropdown,
} from 'react-bootstrap';

//Navbar
import profileImage from '../../../assets/images/profileImage.png';
import chatIcon from '../../../assets/icons/chat.svg';
import walletIcon from '../../../assets/icons/walleticon.svg';
import { headerData } from '../../../services/Homepage';
import { MenuItem } from '../../../models/Homepage';
import Loginform from '../../../utils/Loginform';
import SignUp from '../../../utils/SignUp';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserId, logout } from '../../../redux/authSlice';
import { AppDispatch } from '../../../redux/store';
import { useNavigate } from 'react-router-dom';
// import Loginform from '../modelsdata/Loginform';
// import ForgotPassword from '../modelsdata/ForgotPassword';

function Header() {
  const [show, setShow] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [menuItem, setMenuItem] = useState<MenuItem[]>([]);
  const userId = useSelector(selectUserId);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSignUpClose = () => setShowSignUp(false);
  const handleSignUpShow = () => setShowSignUp(true);

  const getHeaderData = async () => {
    try {
      const resp = await headerData();
      setMenuItem(resp.data.menu);
    } catch (error) {
      console.error('Error fetching header data:', error);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  const handleNavigate = () => {
    navigate('/impactor');
  };
  useEffect(() => {
    getHeaderData();
  }, []);
  return (
    <>
      <header className="header-home">
        <Navbar expand="lg" data-bs-theme="dark">
          <Container>
            <Navbar.Brand href="#home">IMPACTIVATOR</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-start justify-content-md-between nav-bg"
            >
              <Nav className="mx-4">
                {menuItem.map((item, index) =>
                  item.submenus ? (
                    <NavDropdown
                      key={index}
                      title={item.name}
                      id={`${item.name.toLowerCase()}_dropdown`}
                      menuVariant="light"
                    >
                      {item.submenus.map((submenu, subIndex) => (
                        <NavDropdown.Item
                          key={subIndex}
                          onClick={() => navigate(`/${submenu.toLowerCase()}`)}
                          // href={`#${submenu.toLowerCase()}`}
                        >
                          {submenu}
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  ) : (
                    <Nav.Link key={index} href={item.url}>
                      {item.name}
                    </Nav.Link>
                  )
                )}
              </Nav>

              {userId !== 0 ? (
                <div className="d-flex align-items-center justify-content-start">
                  <Nav.Link href="#blog">
                    <Image src={walletIcon} alt="Logo" className="logo mx-2" />
                  </Nav.Link>
                  <div className="d-flex align-items-center justify-content-start">
                    {/* Profile Dropdown */}
                    <Dropdown align="end">
                      <Dropdown.Toggle
                        as="div"
                        className="p-0 border-0 d-flex profile position-relative"
                      >
                        <Image
                          src={chatIcon}
                          alt="Chat Icon"
                          className="position-absolute chat-icon"
                          style={{ top: '-5px', right: '-5px' }}
                        />
                        <Image
                          src={profileImage}
                          alt="Profile Icon"
                          height={48}
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={handleNavigate}>
                          Dashboard
                        </Dropdown.Item>
                        <Dropdown.Item>My Profile</Dropdown.Item>
                        <Dropdown.Item>Change Password</Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout}>
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-start">
                  <Button
                    variant="outline-light"
                    onClick={handleShow}
                    className="home-page-btns"
                  >
                    SIGN IN
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleSignUpShow}
                    className="mx-2 home-page-btns"
                  >
                    SIGN UP
                  </Button>
                </div>
              )}

              {/* <div className="d-flex align-items-center justify-content-start">
                {userId === 0 && (
                  <Button
                    variant="outline-light"
                    onClick={handleShow}
                    className="home-page-btns"
                  >
                    SIGN IN
                  </Button>
                )}
                {userId === 0 && (
                  <Button
                    variant="primary"
                    onClick={handleSignUpShow}
                    className="mx-2 home-page-btns"
                  >
                    SIGN UP
                  </Button>
                )}
              </div> */}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <Modal show={show} onHide={handleClose} scrollable>
        <Loginform handleClose={handleClose} />
        {/* <ForgotPassword /> */}
      </Modal>

      <Modal show={showSignUp} onHide={handleSignUpClose} scrollable size="lg">
        <SignUp onClose={handleSignUpClose} />
      </Modal>
    </>
  );
}

export default Header;
